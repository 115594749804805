<template>
    <div class="pb-5">
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Search results for <span>{{ $route.query.searchKey }}: {{ $route.query.searchTerm }}</span></h1>
                </div>
            </div>
        </title-box>
        <div class="container-fluid mt-3">
            <template v-if="this.selectedShop.applicationId === '0'">
                <mercur-card>
                    <p>Select a shop to enable search</p>
                </mercur-card>
            </template>
            <template v-if="hasPermission('MerchantCentral/searchOrdersByApplication')">
                <div class="row" v-if="items.searchOrdersByApplication">
                    <div class="col-12">
                        <h3>LIST OF ORDERS BY APPLICATION</h3>
                        <template v-if="items.searchOrdersByApplication.orders.length">
                            <merchant-data-table class="shadow" :options="options.orders" :items="items.searchOrdersByApplication.orders"></merchant-data-table>
                        </template>
                        <p v-else>No orders found</p>
                    </div>
                </div>
                <div class="row" v-if="items.searchOrdersByApplication">
                    <div class="col-12">
                        <h3>LIST OF ORDERLINES BY APPLICATION</h3>
                        <template v-if="items.searchOrdersByApplication.orderLines.length">
                            <merchant-data-table class="shadow" :options="options.orderLines" :items="items.searchOrdersByApplication.orderLines"></merchant-data-table>
                        </template>
                        <p v-else>No orderlines found</p>
                    </div>
                </div>
                <div class="row" v-if="items.listAccounts">
                    <div class="col-12">
                        <h3>LIST OF ACCOUNTS BY APPLICATION</h3>
                        <template v-if="items.listAccounts.length">
                            <merchant-data-table class="shadow" :options="options.accounts" :items="items.listAccounts"></merchant-data-table>
                        </template>
                        <p v-else>No accounts found</p>
                    </div>
                </div>
                <div class="row" v-if="items.listInvoicesByApplication">
                    <div class="col-12">
                        <h3>LIST OF INVOICES BY APPLICATION</h3>
                        <template v-if="items.listInvoicesByApplication.invoices.items.length">
                            <merchant-data-table class="shadow" :options="options.invoices" :items="items.listInvoicesByApplication.invoices.items"></merchant-data-table>
                        </template>
                        <p v-else>No invoices found</p>
                    </div>
                </div>
                <div class="row" v-if="items.listQuotes">
                    <div class="col-12">
                        <h3>LIST OF QUOTES BY APPLICATION</h3>
                        <template v-if="items.listQuotes.length">
                            <merchant-data-table class="shadow" :options="options.quotes" :items="items.listQuotes"></merchant-data-table>
                        </template>
                        <p v-else>No quotes found</p>
                    </div>
                </div>
            </template>
            <template v-else>
                Not allowed to see this view
            </template>
        </div>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Currency from '@/components/elements/Currency'

export default {
    name: 'SearchResultsView',
    components: { MerchantDataTable },
    data () {
        return {
            items: [],
            options: {
                orders: {
                    columns: {
                        'Order number': {
                            field: 'orderNumber',
                            link: (value, data) => {
                                return {
                                    name: 'OrderWrapper',
                                    params: {
                                        accountId: data.accountId,
                                        orderId: data.orderId,
                                        applicationId: data.applicationId,
                                    },
                                }
                            },
                        },
                        'Order date': { field: 'dateCreated' },
                        'Customer': {
                            field: 'billingInfo.address.firstName',
                            link: (value, data) => {
                                return {
                                    name: 'CustomerView',
                                    params: {
                                        applicationId: data.applicationId,
                                        customerId: data.accountId,
                                    },
                                }
                            },
                            valueFormatter: params => `${params.data.billingInfo.address.firstName} ${params.data.billingInfo.address.lastName}`,
                        },
                        'Company': { field: 'billingInfo.address.companyName' },
                        'Order status': { field: 'orderStatus', statusChip: true },
                        'Order Total': {
                            field: 'totals.total',
                            cellRendererFramework: Currency,
                            cellRendererParams: (params) => {
                                return {
                                    value: params.value,
                                    type: params.data.currency,
                                }
                            },
                        },
                        'Shop': { field: 'applicationName' },
                    },
                    filter: false,
                    paginationPageSize: 10,
                    defaultColDef: { menuTabs: [] },
                },
                orderLines: {
                    columns: {
                        'Orderline number': {
                            field: 'orderLineNumber',
                            link: (value, data) => {
                                return {
                                    name: 'OrderLineView',
                                    params: {
                                        accountId: data.accountId,
                                        orderId: data.orderId,
                                        orderLineId: data.orderLineId,
                                        applicationId: data.applicationId,
                                    },
                                }
                            },
                        },
                        'Status': { field: 'orderLineStatus', statusChip: true },
                        'Price': {
                            field: 'productPrice',
                        },
                        'Shipping price': { field: 'shippingPrice' },
                        'Dispatch date': { field: 'deliveryInfo.dispatchDate' },
                        'Delivery date': { field: 'deliveryInfo.deliveryDate' },
                        'Delivered to': { valueFormatter: ({ data }) => {
                            if (!data.deliveryInfo) {
                                return
                            }
                            const a = data.deliveryInfo.address
                            return `${a.firstName} ${a.lastName}`
                        },
                        },
                        'Address': { valueFormatter: ({ data }) => {
                            if (!data.deliveryInfo) {
                                return
                            }
                            const a = data.deliveryInfo.address
                            return `${a.address} ${a.zipCode}, ${a.city} - ${a.country}`
                        },
                        },
                    },
                    filter: false,
                    paginationPageSize: 10,
                    defaultColDef: { menuTabs: [] },
                },
                accounts: {
                    columns: {
                        'Customer Id': {
                            field: 'accountId',
                            width: 400,
                            link: (value, data) => {
                                return {
                                    name: 'CustomerView',
                                    params: {
                                        customerId: data.accountId,
                                        applicationId: data.applicationId,
                                    },
                                }
                            },
                        },
                        'First Name': { field: 'firstName', sortable: true },
                        'Last Name': { field: 'lastName', sortable: true },
                        'Email Address': { field: 'email', sortable: true },
                        'Phone Number': { field: 'phone' },
                        'Shop': { field: 'applicationName' },
                        'Group': { field: 'accountSettings.defaultCustomerGroup' },
                        'Revoked': { field: 'dateRevoked' },
                    },
                    filter: false,
                    paginationPageSize: 10,
                    defaultColDef: { menuTabs: [] },
                },
                invoices: {
                    columns: {
                        'Invoice Number': { field: 'invoiceNumber', width: 220 },
                        'Order Id': {
                            field: 'orderNumber',
                            link: (value, data) => {
                                return {
                                    name: 'OrderWrapper',
                                    params: {
                                        accountId: data.accountId,
                                        orderId: data.orderId,
                                        applicationId: data.applicationId,
                                    },
                                }
                            },
                        },
                        'Customer': {
                            field: ['billingInfo.address.firstName', 'billingInfo.address.lastName'],
                            link: (value, data) => {
                                return {
                                    name: 'CustomerView',
                                    params: {
                                        customerId: data.accountId,
                                        applicationId: data.applicationId,
                                    },
                                }
                            },
                        },
                        'Shop': { field: 'applicationName' },
                        'Invoice Date': { field: 'dateCreated' },
                        'Due Date': { field: 'dateCreated' },
                        'Payment Method': { field: 'paymentMethod' },
                        'Invoice Status': { field: 'status', statusChip: true },
                        'Total': { field: 'totals.total' },
                    },
                    filter: false,
                    paginationPageSize: 10,
                    defaultColDef: { menuTabs: [] },
                },
                quotes: {
                    columns: {
                        'Quote Number': {
                            field: 'quoteNumber',
                            width: 400,
                            link: (value, data) => {
                                return {
                                    name: 'EditQuote',
                                    params: {
                                        customerId: data.accountId,
                                        cartId: data.cartId,
                                        applicationId: data.applicationId,
                                    },
                                }
                            },
                        },
                        'Quote Items': {
                            field: '',
                            valueGetter: params => params.data.cartItems.map(e => e.quoteItemNumber).join(', '),
                        },
                        'Quote date': { field: 'dateCreated' },
                        'Customer': {
                            field: 'billingInfo.address.firstName',
                            link: (value, data) => {
                                return {
                                    name: 'CustomerView',
                                    params: {
                                        customerId: data.buyer.accountId,
                                        applicationId: data.applicationId,
                                    },
                                }
                            },
                            valueFormatter: params => `${params.data.billingInfo.address.firstName} ${params.data.billingInfo.address.lastName}`,
                        },
                        'Company': { field: 'billingInfo.address.companyName' },
                        'Quote status': { field: 'status', statusChip: true },
                        'Quote Total': {
                            field: 'totals.total',
                            cellRendererFramework: Currency,
                            cellRendererParams: (params) => {
                                return {
                                    value: params.value,
                                    type: params.data.currency,
                                }
                            },
                        },
                        'Shop': { field: 'applicationName' },
                    },
                    filter: false,
                    paginationPageSize: 10,
                    defaultColDef: { menuTabs: [] },
                },
            },
        }
    },
    watch: {
        '$route.query' () {
            this.search()
        },
    },
    methods: {
        search () {
            if (this.selectedShop.applicationId === '0') {
                this.$root.$emit('notification:global', {
                    message: 'Select a shop to enable search',
                })
                return
            }

            this.$set(this, 'items', [])
            const url = CONFIG.API.ROUTES.SEARCH.OVERVIEW.replace('{agentId}', this.$store.state.auth.user.accountId)
            const payload = {
                searchKey: this.$route.query.searchKey,
                searchTerm: this.$route.query.searchTerm,
            }

            this.post(url, payload).then((data) => {
                this.$set(this, 'items', data.data)
            })
        },
    },
    created () {
        if (!this.hasPermission('searchOrdersByApplication')) {
            return
        }
        this.search()
    },
}
</script>

<style lang="scss" scoped>
    .c-standard-table {
        min-height: 200px !important;
    }
</style>
